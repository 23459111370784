const data = [
    {
    id: 1,
    title: 'القهوة الساخنة',
    image: '../../../img/list/coffe-cup.png',
    selected: true,
    items: [
      {
          id: 1,
          title: 'سبانش لاتيه',
          description: '',
          image: '../../img/food/span.jpg',
          price: 15,
          calories: 209,
          selected: false,
      },
      {
          id: 2,
          title: 'بستاشيو لاتيه',
          description: '',
          image: '../../img/food/pstachio.png',
          price: 15,
          calories: 180,
          selected: false,
      },
      {
          id: 3,
          title: 'كابتشينو',
          description: '',
          image: '../../img/food/capatchino.png',
          price: 15,
          calories: 144,
          selected: false,
      },
      {
          id: 4,
          title: 'لاتية',
          description: '',
          image: '../../img/food/late.png',
          price: 15,
          calories: 151,
          selected: false,
      },
      {
          id: 5,
          title: 'موكا ساخن',
          description: '',
          image: '../../img/food/moka.png',
          price: 15,
          calories: 215,
          selected: false,
      },
      {
          id: 6,
          title: 'أمريكانو',
          description: '',
          image: '../../img/food/amrico.png',
          price: 15,
          calories: 12,
          selected: false,
      },
      {
          id: 7,
          title: 'ماكياتو',
          description: '',
          image: '../../img/food/maciato.png',
          price: 15,
          calories: 90,
          selected: false,
      },
      {
          id: 8,
          title: 'إطاليانو',
          description: '',
          image: '../../img/food/italiano.png',
          price: 15,
          calories: 189,
          selected: false,
      },

 {
           id: 9,
           title: 'كورتادو',
           description: '',
           image: '../../img/food/cortado.png',
           price: 15,
           calories: 162,
           selected: false,
   },
      {
          id: 10,
          title: 'إسبرسو',
          description: '',
          image: '../../img/food/ispressop.png',
          price: 10,
          calories: 4,
          selected: false,
      },
      {
          id: 11,
          title: 'دبل إسبرسو',
          description: '',
          image: '../../img/food/ispressop.png',
          price: 12,
          calories: 8,
          selected: false,
      },
      {
          id: 12,
          title: 'قهوة تركية',
          description: '',
          image: '../../img/food/turkish.png',
          price: 10,
          calories: 5,
          selected: false,
      },
      {
          id: 13,
          title: 'قهوة فرنسية',
          description: '',
          image: '../../img/food/frnch.jpg',
          price: 15,
          calories: 7,
          selected: false,
      },    
      {
        id: 14,
        title: 'دلة قهوة سعودية',
        description: 'كوب - دلة وسط - دلة كبير',
        image: '../../img/food/dlah.png',
        price: '8-16-20',
        calories: 6,
        selected: false,
    },  
  ]
},
{
    id: 2,
    title: 'القهوة الباردة',
    image: '../../../img/list/bard.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'ايس سبانش لاتيه',
            description: '',
            image: '../../img/food/spanish.png',
            price: 15,
            calories: 206,
            selected: false,
        },
        {
            id: 2,
            title: 'بستاشيو لاتيه',
            description: '',
            image: '../../img/food/bstachio.png',
            price: 15,
            calories: 250,
            selected: false,
        },
        {
            id: 3,
            title: 'زعفران لاتيه',
            description: '',
            image: '../../img/food/saffron.png',
            price: 15,
            calories: 250,
            selected: false,
        },
        {
            id: 4,
            title: 'كراميل لاتيه',
            description: '',
            image: '../../img/food/caramel.png',
            price: 15,
            calories: 206,
            selected: false,
        },
        {
            id: 5,
            title: ' موكا لاتية',
            description: '',
            image: '../../img/food/icemoca.png',
            price: 15,
            calories: 240,
            selected: false,
        },

        {
            id: 6,
            title: 'أمريكانو',
            description: '',
            image: '../../img/food/iceamricano.png',
            price: 15,
            calories: 15,
            selected: false,
        },
        {
            id: 7,
            title: 'ريد فيلفيت',
            description: '',
            image: '../../img/food/redf.jpeg',
            price: 15,
            calories: 204,
            selected: false,
        },

        {
            id: 8,
            title: 'آيس إيطاليانو',
            description: '',
            image: '../../img/food/icemoca.png',
            price: 15,
            calories: 227,
            selected: false,
        },
        {
            id: 9,
            title: 'آيس فرنسي',
            description: '',
            image: '../../img/food/caramel.png',
            price: 15,
            calories: 227,
            selected: false,
        },
        {
            id: 10,
            title: 'موكا',
            description: '',
            image: '../../img/food/Chocolaty.png',
            price: 15,
            calories: 227,
            selected: false,
        },

    ]
},
{
    id: 3,
    title: 'الشاي',
    image: '../../../img/list/tea.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'شاي أحمر',
            description: 'كوب صغير - كوب كبير - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/shahya7mr.png',
            price: '3-4-12-16-20',
            calories: '',
            selected: false,
        },
        {
            id: 2,
            title: 'شاي أخضر',
            description: 'كوب - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/shahya5dr.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },
        {
            id: 3,
            title: 'شاي كرك - عدني',
            description: 'كوب - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/adni.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },
        {
            id: 4,
            title: 'شاي أنجليزي',
            description: 'كوب - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/englishtea.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },
        {
            id: 5,
            title: 'شاي مغربي',
            description: 'كوب - براد صغير - براد كبير',
            image: '../../img/food/mghrbi.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },
        {
            id: 6,
            title: 'ينسون',
            description: 'كوب - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/yanson.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },
        {
            id: 7,
            title: 'كركدية',
            description: 'كوب - براد صغير - براد وسط - براد كبير',
            image: '../../img/food/krkdih.png',
            price: '5-15-20-25',
            calories: '',
            selected: false,
        },

    ]
},
{
    id: 4,
    title: 'الحلا',
    image: '../../../img/list/soit.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'كيك ريد فيلفت',
            description: '',
            image: '../../img/food/redfilfit.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 2,
            title: 'كيك الشوكلاتة',
            description: '',
            image: '../../img/food/choklatkik.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 3,
            title: 'تشيز كيك لوتس',
            description: '',
            image: '../../img/food/lots.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 4,
            title: ' تشيز كيك توت أزرق',
            description: '',
            image: '../../img/food/kikazr8.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 5,
            title: ' تشيز كيك توت أحمر',
            description: '',
            image: '../../img/food/a7mrkik.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 6,
            title: ' تشيز كيك أوريو',
            description: '',
            image: '../../img/food/orio.jpg',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 7,
            title: 'حلا الكندر',
            description: '',
            image: '../../img/items.png',
            price: 12,
            calories: 240,
            selected: false,
        },
        {
            id: 8,
            title: 'حلا جالكسي',
            description: '',
            image: '../../img/items.png',
            price: 10,
            calories: 240,
            selected: false,
        },
        {
            id: 9,
            title: 'حلا سنكرز',
            description: '',
            image: '../../img/items.png',
            price: 10,
            calories: 240,
            selected: false,
        },
        {
            id: 10,
            title: 'حلا الأوريو',
            description: '',
            image: '../../img/items.png',
            price: 10,
            calories: 240,
            selected: false,
        },
        {
            id: 11,
            title: 'حلا الجوزاء',
            description: '',
            image: '../../img/items.png',
            price: 10,
            calories: 240,
            selected: false,
        },
        {
            id: 12,
            title: 'حلا الخشخش',
            description: '',
            image: '../../img/items.png',
            price: 10,
            calories: 240,
            selected: false,
        },
    ]
},
{
    id: 5,
    title: 'الموهيتو',
    image: '../../../img/list/mohi.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'موهيتو توت أحمر',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },
        {
            id: 2,
            title: 'موهيتو توت أزرق',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },
        {
            id: 3,
            title: 'موهيتو رمان',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },
        {
            id: 4,
            title: 'موهيتو فراولة ',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },          
        {
            id: 5,
            title: 'موهيتو بطيخ ',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },    
        {
            id: 6,
            title: 'موهيتو كرز ',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },
        
        {
            id: 7,
            title: 'موهيتو مكس',
            description: 'كودرد - سفن أب',
            image: '../../img/items.png',
            price: 15,
            calories: 110,
            selected: false,
        },
        {
            id: 8,
            title: 'موهيتو ريدبول',
            description: ' مشروب الطاقة ريدبول بجميع النكهات السابقة',
            image: '../../img/items.png',
            price: 20,
            calories: 150,
            selected: false,
        },
    ]
},
{
    id: 6,
    title: 'العصائر',
    image: '../../../img/list/juc.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'كوكتيل',
            description: '',
            image: '../../img/food/koktil.png',
            price: 15,
            calories: 83,
            selected: false,
        },
        {
            id: 2,
            title: 'أفوكادو',
            description: '',
            image: '../../img/food/afokado.png',
            price: 15,
            calories: 151,
            selected: false,
        },
        {
            id: 3,
            title: 'كيوي',
            description: '',
            image: '../../img/food/kiwi.png',
            price: 15,
            calories: 64,
            selected: false,
        },
        {
            id: 4,
            title: 'بطيخ',
            description: '',
            image: '../../img/food/b6e5.png',
            price: 15,
            calories: 45,
            selected: false,
        },
        {
            id: 5,
            title: 'رمان',
            description: '',
            image: '../../img/food/roman.png',
            price: 15,
            calories: 47,
            selected: false,
        },
        {
            id: 6,
            title: 'أناناس',
            description: '',
            image: '../../img/food/ananas.png',
            price: 15,
            calories: 62,
            selected: false,
        },

        {
            id: 7,
            title: 'شمام',
            description: '',
            image: '../../img/food/shmam.png',
            price: 15,
            calories: 75,
            selected: false,
        },

        {
            id: 8,
            title: 'تفاح',
            description: '',
            image: '../../img/food/tofa7.png',
            price: 15,
            calories: 65,
            selected: false,
        },

        {
            id: 9,
            title: 'فراولة',
            description: '',
            image: '../../img/food/fraolh.png',
            price: 15,
            calories: 51,
            selected: false,
        },

        {
            id: 10,
            title: 'فراولة حليب',
            description: '',
            image: '../../img/food/fraolh.png',
            price: 15,
            calories: 83,
            selected: false,
        },
        {
            id: 11,
            title: 'مانجو',
            description: '',
            image: '../../img/food/mango.png',
            price: 15,
            calories: 64,
            selected: false,
        },
        {
            id: 12,
            title: 'برتقال',
            description: '',
            image: '../../img/food/bort8al.png',
            price: 15,
            calories: 42,
            selected: false,
        },
        {
            id: 13,
            title: 'برتقال نعناع',
            description: '',
            image: '../../img/food/bort8al.png',
            price: 15,
            calories: 47,
            selected: false,
        },
        {
            id: 14,
            title: 'ليمون',
            description: '',
            image: '../../img/food/limon3.png',
            price: 15,
            calories: 30,
            selected: false,
        },
        {
            id: 15,
            title: 'ليمون نعناع',
            description: '',
            image: '../../img/food/limon3.png',
            price: 15,
            calories: 34,
            selected: false,
        },
        {
            id: 16,
            title: 'موز بالحليب',
            description: '',
            image: '../../img/food/mozmilk.png',
            price: 15,
            calories: 80,
            selected: false,
        },
        {
            id: 17,
            title: 'جوافة',
            description: '',
            image: '../../img/food/jawafh.png',
            price: 15,
            calories: 66,
            selected: false,
        },
    ]
},
{
    id: 7,
    title: 'المشروبات الغازية',
    image: '../../../img/list/other.png',
    selected: false,
    items: [
        {
            id: 1,
            title: ' ريد بول ',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/redbull.png',
            price: 20,
            calories: 210,
            selected: false,
        },
        {
            id: 2,
            title: 'باورهورس',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/pawer.png',
            price: 20,
            calories: 210,
            selected: false,
        },
        {
            id: 3,
            title: 'كودرد',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/codred.png',
            price: 10,
            calories: 210,
            selected: false,
        },
        {
            id: 4,
            title: 'بايسن',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/bison.png',
            price: 10,
            calories: 210,
            selected: false,
        },
        {
            id: 5,
            title: 'هولستن',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/holiston.png',
            price: 8,
            calories: 210,
            selected: false,
        },
        {
            id: 6,
            title: 'موسي',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/mosi.png',
            price: 8,
            calories: 210,
            selected: false,
        },
        {
            id: 7,
            title: 'كاربو',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/carabao.png',
            price: 8,
            calories: 210,
            selected: false,
        },
        {
            id: 8,
            title: 'ليبتون آيس تي',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/lipton.png',
            price: 8,
            calories: 210,
            selected: false,
        },
        {
            id: 9,
            title: 'بيبسي',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/pebsi.jpg',
            price: 7,
            calories: 183,
            selected: false,
        },
        {
            id: 10,
            title: 'سفن اب',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/sfn.jpeg',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 11,
            title: 'ميرندا برتقال',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/mirbort8al.png',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 12,
            title: 'ميرندا ديو',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/dew.png',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 13,
            title: 'ميرندا حمضيات',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/hm6yat.png',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 14,
            title: 'ميرندا فراولة',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/mirfraolh.png',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 15,
            title: 'كوكاكولا',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/cocacola.png',
            price: 7,
            calories: 184,
            selected: false,
        },
        {
            id: 16,
            title: 'بلاك',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/black.png',
            price: 7,
            calories: 210,
            selected: false,
        },
        {
            id: 17,
            title: 'ريتا',
            description: 'مع كوباً من الثلج',
            image: '../../img/food/reta.png',
            price: 7,
            calories: 210,
            selected: false,
        },
        {
            id: 18,
            title: 'مياة معدنية',
            description: '',
            image: '../../img/food/ber.png',
            price: 1,
            calories: 0,
            selected: false,
        },
    ]
},
{
    id: 8,
    title: 'شيشة صالة vip',
    image: '../../../img/list/shisha.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'معسل تفاحتين فاخر',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 2,
            title: 'معسل تفاحتين نخلة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 3,
            title: 'معسل مكس [ فاخر + نخلة ]',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 4,
            title: 'معسل عنب توت',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 5,
            title: 'معسل عنب نعناع',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 6,
            title: 'معسل عنب فاخر',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 7,
            title: 'معسل ليمون نعناع',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 8,
            title: 'معسل فراولة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
        {
            id: 9,
            title: 'معسل نعناع سادة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/shishavip.png',
            price: 40,
            calories: 0,
            selected: false,
        },
    ]
},
{
    id: 9,
    title: 'شيشة صالة 1',
    image: '../../../img/list/shisha.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'معسل تفاحتين فاخر',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 2,
            title: 'معسل تفاحتين نخلة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 35,
            calories: 0,
            selected: false,
        },
        {
            id: 3,
            title: 'معسل مكس [ فاخر + نخلة ]',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 4,
            title: 'معسل عنب توت',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 5,
            title: 'معسل عنب نعناع',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 6,
            title: 'معسل عنب فاخر',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 7,
            title: 'معسل ليمون نعناع',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 8,
            title: 'معسل فراولة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
        {
            id: 9,
            title: 'معسل نعناع سادة',
            description: 'مدة الجلوس على الطاولة ساعتين - تغيير الراس ب 25 ريال',
            image: '../../img/food/Shisha.jpg',
            price: 30,
            calories: 0,
            selected: false,
        },
    ]
},
{
    id: 10,
    title: 'الغرف الخاصة',
    image: '../../../img/list/shisha.png',
    selected: false,
    items: [
        {
            id: 1,
            title: 'معسل تفاحتين فاخر',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 2,
            title: 'معسل تفاحتين نخلة',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 3,
            title: 'معسل مكس [ فاخر + نخلة ]',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 4,
            title: 'معسل عنب توت',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 5,
            title: 'معسل عنب نعناع',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 6,
            title: 'معسل عنب فاخر',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 7,
            title: 'معسل ليمون نعناع',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 8,
            title: 'معسل فراولة',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
        {
            id: 9,
            title: 'معسل نعناع سادة',
            description: 'مدة الجلوس في الغرفة ساعتين فقط',
            image: '../../img/food/shusha5a9.png',
            price: 150,
            calories: 0,
            selected: false,
        },
    ]
},
]
export default data 